html {
  scroll-behavior: smooth;
}

body {
  font-family: "Helvetica", "Arial", sans-serif;
  background-color: #c2dbff3d;
  overflow-x: hidden;
}
.navbar {
  min-height: 80px;
  .navbar-brand {
    object {
      width: 115px;
      height: auto;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray-500;
  border-radius: 90px;
}

.img-header {
  width: 141px;
  height: auto;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.form-logo {
  margin-bottom: 40px;
}

.title-l {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
}

.title-m {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #171717;
}

.title-s {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #171717;
}

.body-m {
  font-family: "Helvetica Neue", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #737373;
}

.body-s {
  font-family: "Helvetica Neue", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #737373;

  &.darker {
    color: #171717;
  }
}

.line-header {
  .row {
    .col-8 {
      margin: 0;
      padding: 0;
      hr {
        margin: 0;
        height: 6px;
        background-color: #4eacea;
      }
    }
    .col-4 {
      margin: 0;
      padding: 0;
      hr {
        height: 6px;
        background-color: #005fa2;
        margin: 0;
      }
    }
  }
}

.nav-header-custom {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 10px;
  margin-right: 10px;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(105, 99, 99, 0.75) !important;
}

.bg-img-hero-business {
  background-image: url("../img/Hero/HeroBisnis.png") !important;
}
.bg-img-hero {
  background-image: url("../img/Hero/Hero.png") !important;
}

.Hero {
  background-color: #009dff11 !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 700px;
  display: flex;
}

.circle {
  background: $gray-600;
  border-radius: 20px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.card-hero {
  position: relative;
  background: #fff;
  border-radius: 16px;
  padding: 40px;
  // top: 80px;

  hr {
    margin: 24px 0;
  }

  .title-card-bisnis {
    width: 461px !important;
  }

  .title-card-hero {
    margin: 24px 0;
    line-height: 68px;
    font-weight: 700;
    font-size: 54px;
    width: 565px;
    text-align: left;
    // span {
    //   color: $blue;
    // }
    // p {
    //   color: $blue;
    // }
  }
  .content-card-hero {
    width: 543px;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    color: $gray-600;
  }
}

.btn-whatsApp {
  color: #fff !important;
  background: #7db139;
  padding: 12px 24px;
  display: flex;
  border-radius: 99px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-left: 10px;
  &:hover {
    text-decoration: none;
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: 5px;
    width: 25px;
    height: 25px;
  }
}

.btn-container {
  display: flex;
  flex-direction: row;
  @media (max-width: 500px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 5px;
  }
}

.btn-default {
  color: #fff !important;
  background: $blue;
  padding: 12px 24px;
  display: flex;
  border-radius: 99px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-left: 10px;

  @media (max-width: 500px) {
    min-width: 40%;
    padding: 6px 12px;
  }

  &:hover {
    text-decoration: none;
  }
  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
}

.btn-light {
  color: $blue !important;
  background: $blueLight;
  padding: 12px 24px;
  display: flex;
  border-radius: 99px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-left: 10px;

  @media (max-width: 500px) {
    min-width: 45%;
    padding: 8px 12px;
  }

  &:hover {
    text-decoration: none;
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    width: max-content;
    display: flex;
    align-items: center;
  }
  svg {
    width: 22px;
    margin-left: 10px;
    margin-top: -3px;
  }
}

.gradient-bg {
  background-image: linear-gradient(#f1f6ff, $white);
}

.gradient-bg-to {
  background-image: linear-gradient($white, #f1f6ff);
}

.seccsion {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  .btn-default {
    margin-top: 48px;
  }
  .title-seccsion {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    line-height: 36px;
  }
  .desc-seccsion {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    width: 626px;
    margin-top: 16px;
    color: $gray-600;
  }
  .content-card-seccsion {
    display: flex;
    width: 1200px;
    flex-wrap: wrap;
    margin-top: 32px;
    justify-content: space-around;
    .card-seccsion {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      background: #fff;
      border-radius: 24px;
      width: 375px;
      height: 248px;

      svg {
        border-radius: 24px;
        background: $blueLight;
        width: 64px;
        height: 64px;
        color: $blue;
        padding: 10px;
      }
      .title-card-seccsion {
        font-size: 24px;
        font-weight: 700;
        margin-top: 24px;
        span {
          color: $blue;
        }
      }
      .desc-card-seccsion {
        white-space: pre-wrap;
        text-align: center;
        font-size: 16px;
        margin-top: 8px;
        font-weight: 400;
        color: $gray-600;
      }
    }
  }
  .syarat-dan-ketentuan {
    margin-top: 24px;
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    color: $gray-600;
  }
}

.people {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $white;
  .title-people {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    line-height: 36px;
    margin-top: 12px;
  }
  .card-people {
    background: #fff;
    width: 583px;
    height: 280px;
    padding: 32px;
    border-radius: 16px;
    margin-top: 60px;
    border: 1px solid #e5e5e5;

    img {
      border-radius: 99px;
      width: 96px;
      height: 96px;
      margin-right: 15px;
    }
    .name-people {
      font-weight: 700;
      font-size: 24px;
      color: $black2;
    }
    .location-people {
      font-size: 16px;
      font-weight: 400;
      color: $gray-700;
    }
    .desc-people {
      font-size: 16px;
      font-weight: 400;
      color: $gray-600;
      text-align: left;
      margin-top: 24px;
    }
  }
  .content-pagination {
    margin-top: 40px;
    margin-bottom: 64px;
    .paging-people-arraw {
      background: #fff;
      color: $blue;
      border-radius: 99px;
      border: 1px solid $gray-500;
      padding: 10px;
      cursor: pointer;
      &:hover {
        background: $blue;
        color: #fff;
      }
    }
    .paging-number-people {
      font-size: 16px;
      font-weight: 700;
      color: $blue;
    }
  }
}

.ImageDesc {
  background: $white;
  .imageFirst {
    background-repeat: no-repeat;
    background-position: center bottom;
    // background-image: linear-gradient(to bottom right, #0069b449, #0069b4),
    //   url("../img/image1.png");
    background-size: cover;
    min-height: 384px;
  }
  .imageSeccond {
    background-repeat: no-repeat;
    background-position: center bottom;
    // background-image: linear-gradient(to bottom right, #0069b449, #0069b4),
    //   url("../img/image2.png");
    background-size: cover;
    min-height: 384px;
  }
  .content-image {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 242px 0px 0px 107px;
    .number-content {
      font-size: 48px;
      color: #fff;
      font-weight: 700;
    }
    .decs-content {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 700;
      color: #fff;
      .update-content {
        font-size: 12px;
        font-weight: 400;
        margin-left: 183px;
      }
    }
  }
}

.proses {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  .title-proses {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    line-height: 36px;
  }
  .desc-proses {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    width: 668px;
    margin-top: 10px;
    color: $gray-600;
  }
  .content-desc-prosess {
    padding-right: 96px;
  }
  img {
    width: 403px;
    height: 252px;
    border-radius: 16px;
  }

  .number-content-proses {
    font-size: 32px;
    color: $blue;
    font-weight: 700;
    background-color: $blueLight;
    border-radius: 16px;
    height: 56px;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title-content-proses {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;
    width: 372px;
    text-align: left;
  }
  .desc-content-proses {
    font-size: 16px;
    font-weight: 400;
    color: #737373;
    text-align: left;
    margin-top: 16px;
  }
  .btn-default {
    margin-top: 96px;
  }
}

.more-efficient {
  .img-more {
    background-repeat: no-repeat;
    background-position: center bottom;
    // background-image: url("../img/more.png");
    background-size: cover;
    min-height: 517px;
  }
  .desc-more-content {
    background-color: $blue;
    display: flex;
    height: 517px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 6rem;
    .img-rectangle {
      position: absolute;
      left: 0;
      top: 0;
    }
    .img-subtract {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .content-more {
      text-align: left;
      .more-title {
        width: 464px;
        font-size: 32px;
        font-weight: 700;
        color: #fff;
      }
      .more-desc {
        width: 464px;
        font-size: 16px;
        font-weight: 400;
        color: $gray-500;
        margin-top: 16px;
      }
    }
    .btn-light {
      margin-left: 0;
      margin-top: 3rem;
    }
  }
}

.ModalContentQuest {
  width: 814px;
  background: #fff;
  border-radius: 10px;
  .ModalHeader {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid $gray-600;
    border-color: #e5e5e5;
    padding: 20px;
    background-color: #f5f5f5;
    .ModalQuestTitle {
      font-weight: 700;
      font-size: 24px;
      color: $black2;
    }
    img {
      cursor: pointer;
    }
  }
  .ModalBody {
    overflow-y: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 20px;
    hr {
      margin: 32px 0;
    }
  }
}

.title-content-questions {
  font-size: 18px;
  font-weight: 400;
  color: $black2;
  margin-right: 40px;
}
.desc-content-questions {
  font-size: 18px;
  font-weight: 400;
  color: $gray-600;
  padding-top: 15px;
}
.desc-content-questions-close {
  height: min-content;
  display: none;
  transition: display height 2s;
}
.arrow-icon-question {
  color: $blue;
}
.questions {
  padding: 128px;
  min-height: 500px;
  background-color: #fff;
  hr {
    margin: 32px 0;
  }
  .title-questions {
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    color: $black2;
    margin-bottom: 16px;
  }
  .desc-questions {
    font-size: 18px;
    font-weight: 400;
    color: $gray-600;
  }
  .text-look-all-questions {
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    margin-top: 3rem;
    color: $blue;
    display: flex;
    justify-content: center;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-content {
  padding: 108px 128px 1% 128px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  .custom-container-footer {
    margin-left: 145px;
  }
  .img-widht {
    width: 141px;
    height: auto;
  }
  .title-footer {
    font-size: 14px;
    font-weight: 700;
    color: $black2;
    cursor: pointer;
  }
  .content-footer {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: $gray-600;
    cursor: pointer;
    svg {
      margin-right: 8px;
    }
  }
  .item-icon {
    margin-right: 8px;
  }
  hr {
    width: 100%;
  }
  .footer-icon-item {
    display: flex;
    justify-content: space-between;
    .footer-copyright {
      font-size: 14px;
      font-weight: 400;
      color: #737373;
    }
    .icon-social-footer {
      display: flex;
      padding: 9px;
      border-radius: 100px;
      background: #f5f5f5;
      align-items: center;
      justify-content: center;
      img {
        padding: 0 2px;
      }
    }
  }
}
.logoClient {
  padding: 3rem 0;
  background-color: #fff;
  .content-icon-client {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .logo-text-client {
    text-align: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    margin: 56px 0;
  }
}

.calculatorContent {
  padding: 128px 0;
  img {
    width: 100%;
    padding: 0 1rem;
    border-radius: 25px;
  }

  .calculatorContentItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .btn-default {
      margin-top: 40px;
    }
    .calculator-efficency {
      font-weight: 700;
      padding: 0.5rem 1rem;
      font-size: 14px;
      color: $blue;
      background-color: $blueLight;
      border-radius: 30px;
    }
    .calculator-title {
      width: 415px;
      line-height: 42px;
      font-weight: 700;
      font-size: 32px;
      color: $black2;
      padding: 1rem 0;
      span {
        color: $blue;
      }
    }
    .calculator-desc {
      width: 415px;
      font-weight: 400;
      font-size: 16px;
      color: $gray-600;
    }
  }
}

.natural-gas {
  padding: 4rem 0;
  background-color: #fff;
  .title-natural-gas {
    width: 684px;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: $black2;
    text-align: center;
  }
  .desc-natural-gas {
    width: 537px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $gray-600;
    text-align: center;
    margin-top: 16px;
  }
  .card-natural-gas {
    background-color: #fff;
    margin-top: 64px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    display: flex;
    flex-direction: column;
    img {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .card-content-np {
      padding: 24px;
      .card-title-np {
        font-weight: 700;
        font-size: 24px;
        width: 320px;
        line-height: 32px;
        color: $black2;
      }
      .card-desc-np {
        margin-top: 8px;
        width: 320px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $gray-600;
      }
    }
  }
}

.provide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 108px;
  .btn-default {
    margin-top: 24px;
  }
  .title-provide {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    line-height: 36px;
  }
  .desc-provide {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    width: 626px;
    margin-top: 16px;
    color: $gray-600;
  }
  .card-provide-content {
    display: flex;
    width: 1200px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 3rem 0;
    .card-margin-provide {
      margin: 32px 0;
    }
    .card-number-provide {
      font-weight: 700;
      font-size: 32px;
      position: fixed;
      color: $blue;
      background: $blueLight;
      padding: 0 1rem;
      border-radius: 13px;
      top: -26px;
      left: 45px;
    }
    .field-card-provide {
      background-color: #fff;
      border-radius: 20px;
      width: 349px;
      height: 187px;
      padding: 18px 30px 0px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title-card-provide {
        width: max-content;
        font-size: 24px;
        font-weight: 700;
      }
      .desc-card-provide {
        font-size: 16px;
        margin-top: 8px;
        font-weight: 400;
        color: $gray-600;
      }
    }
  }
  .text-content-provide {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: $blue;
  }
}

@media (max-width: 1400px) {
  .ImageDesc {
    .content-image {
      margin: 261px 0px 0px 12px;
      .number-content {
        font-size: 32px;
      }
      .decs-content {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .seccsion {
    .content-card-seccsion {
      width: 787px;
    }
  }
  .provide {
    .card-provide-content {
      width: 787px;
    }
  }
  .ImageDesc {
    .content-image {
      margin: 261px 0px 0px 12px;
      .number-content {
        font-size: 32px;
      }
      .decs-content {
        flex-direction: column;
        align-items: flex-start;
        font-size: 16px;
        .update-content {
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }
  }

  .more-efficient {
    .desc-more-content {
      background-color: $blue;
      display: flex;
      height: 517px;
      padding: 4rem;
      .img-rectangle {
        width: 20rem;
      }
      .img-subtract {
        width: 4rem;
      }
      .content-more {
        .more-title {
          width: 300px;
          font-size: 24px;
        }
        .more-desc {
          width: 279px;
          font-size: 14px;
          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .card-hero {
    .content-card-hero,
    .title-card-hero {
      width: auto;
      max-width: 100%;
    }
  }
}

@media (max-width: 500px) {
  body {
    overflow-x: hidden;
  }

  .App {
    overflow-x: hidden;
  }

  .form-logo {
    margin-bottom: 16px;
  }

  .title-l {
    font-size: 24px;
    line-height: 32px;
  }

  .title-m {
    font-size: 24px;
    line-height: 32px;
  }

  .title-s {
    font-size: 18px;
    line-height: 24px;
  }

  .body-m {
    font-size: 14px;
    line-height: 20px;
  }

  .body-s {
    font-size: 12px;
    line-height: 16px;
  }

  .ModalContentQuest {
    width: 456px;
    background: #fff;
    border-radius: 10px;
  }
  .btn-default {
    margin-bottom: 1em;
    .title {
      font-size: 12px;
    }
  }

  .btn-light {
    margin-left: 0;
    .title {
      font-size: 12px;
      font-weight: 700;
    }
    svg {
      width: 13px;
      margin-left: 10px;
      margin-top: -3px;
    }
  }
  .btn-mobile {
    padding: 16px 48px;
    .title {
      font-size: 14px;
    }
    svg {
      width: 25px;
    }
  }

  .bg-img-hero-business {
    background-image: url("../img/Hero/HeroBisnisMobile.png") !important;
  }
  .bg-img-hero {
    background-image: url("../img/Hero/HeroMobile.png") !important;
  }

  .Hero {
    min-height: 750px;
  }

  .card-hero {
    margin: auto calc(1rem - 8px);
    border-radius: 16px;
    padding: 20px;
    & > div {
      width: calc(100vw - 2rem - 40px);
    }
    .title-card-bisnis {
      width: 293px !important;
    }

    .title-card-hero {
      line-height: 36px;
      font-size: 32px;
      width: 300px;
      max-width: 100%;
    }
    .content-card-hero {
      font-size: 12px;
      width: 294px;
      max-width: 100%;
    }
  }

  .modalNavCustomHero {
    padding: 2rem;
    .modalNavHeaderHero {
      display: flex;
      justify-content: space-between;
      img {
        width: 7rem;
      }
      span {
        font-size: 24px;
        font-weight: 400;
        cursor: pointer;
      }
    }
    .items-modal {
      margin-top: 25px;
      display: flex;
      font-size: 24px;
      font-weight: 700;
      flex-direction: column;
      a {
        color: $black2 !important;
        margin-top: 20px;
      }
    }
    .btn-default {
      padding: 12px 24px;
      .title {
        font-size: 16px;
      }
    }
  }

  .seccsion {
    padding: 0 0;
    padding-bottom: 2rem;
    .title-seccsion {
      width: 272px;
      font-size: 24px;
      margin-top: 40px;
    }
    .desc-seccsion {
      width: 379px;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .content-card-seccsion {
      width: 383px;
      .card-seccsion {
        width: 180px;
        height: auto;
        padding-left: 5px;
        padding-right: 5px;
        .title-card-seccsion {
          font-size: 16px;
        }
        .desc-card-seccsion {
          font-size: 12px;
        }
      }
    }
  }

  .provide {
    .title-provide {
      width: 272px;
      font-size: 24px;
    }
    .desc-provide {
      width: 379px;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .card-provide-content {
      width: 444px;
      .card-number-provide {
        font-weight: 700;
        font-size: 32px;
        position: fixed;
        color: $blue;
        background: $blueLight;
        padding: 0 1rem;
        border-radius: 13px;
        top: -7px;
        left: 45px;
      }
      .field-card-provide {
        .title-card-seccsion {
          font-size: 16px;
        }
        .desc-card-seccsion {
          font-size: 12px;
        }
      }
    }
  }

  .people {
    .title-people {
      width: 272px;
      font-size: 24px;
      margin-top: 40px;
    }
    .card-people {
      background: #fff;
      width: 346px;
      height: auto;
      padding: 32px;
      border-radius: 16px;
      margin-top: 60px;
      border: 1px solid #e5e5e5;

      img {
        border-radius: 99px;
        width: 96px;
        height: 96px;
        margin-right: 15px;
      }
      .name-people {
        font-size: 18px;
      }
      .location-people {
        font-size: 14px;
      }
      .desc-people {
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }
  .ImageDesc {
    .content-image {
      margin: 261px 0px 0px 12px;
      .number-content {
        font-size: 32px;
      }
      .decs-content {
        flex-direction: column;
        align-items: flex-start;
        font-size: 16px;
        .update-content {
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }
  }

  .proses {
    .title-proses {
      width: 272px;
      font-size: 24px;
    }
    .content-desc-prosess {
      padding-right: 0;
    }
    .title-content-proses {
      font-size: 24px;
      font-weight: 700;
      margin-top: 10px;
      width: 226px;
      text-align: left;
    }
    .desc-proses {
      width: 272px;
      font-size: 18px;
      margin-bottom: 20px;
    }
    img {
      margin-top: 25px;
      width: 283px;
      height: auto;
      border-radius: 16px;
    }
  }

  .questions {
    padding: 40px;
    .title-questions {
      width: 352px;
      font-size: 24px;
      margin-top: 40px;
    }
    .desc-questions {
      width: 379px;
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  .more-efficient {
    .img-more {
      min-height: 318px;
    }
    .desc-more-content {
      display: flex;
      height: 430px;
      padding: 4rem;
      .img-rectangle {
        width: 20rem;
      }
      .img-subtract {
        width: 4rem;
      }
      .content-more {
        margin-top: -28px;
        text-align: left;
        .more-title {
          width: 300px;
          font-size: 24px;
        }
        .more-desc {
          width: 279px;
          font-size: 14px;
          margin-top: 20px;
        }
      }
      .btn-light {
        margin-left: 0;
        margin-top: 3rem;
      }
    }
  }

  .footer-content {
    padding: 4% 10% 1% 10%;
    .custom-container-footer {
      margin-left: 0;
    }
    .footer-icon-item {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }
  }

  .icon-social-container {
    margin: 1em 0;
  }

  .calculatorContent {
    padding: 2rem 0;
    .calculatorContentItem {
      .calculator-title {
        width: 301px;
        font-size: 24px;
      }
      .calculator-desc {
        width: 280px;
        font-size: 14px;
      }
    }
  }

  .natural-gas {
    padding: 2rem 0;
    background-color: #fff;
    .title-natural-gas {
      width: 300px;
      font-size: 32px;
    }
    .desc-natural-gas {
      width: 300px;
      font-size: 18px;
    }
    .card-natural-gas {
      background-color: #fff;
      margin-top: 2rem;
      border-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      display: flex;
      flex-direction: column;
      img {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
      .card-content-np {
        padding: 8px 16px;
        .card-title-np {
          font-weight: 700;
          font-size: 24px;
          width: 320px;
          line-height: 32px;
          color: $black2;
        }
        .card-desc-np {
          margin-top: 5px;
          width: 320px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $gray-600;
        }
      }
    }
  }
}
