body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.popover {
  border-style: none !important;
  background-color: transparent !important;
  margin-top: 0px !important;
}

.tooltip-body {
  box-sizing: border-box;
  padding: 24px;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 8px 16px rgba(23, 23, 23, 0.1);
  border-radius: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background-color: #FFF;
}
